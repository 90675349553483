import * as React from "react"

import "./burger-button.scss"

function BurgerButton({onChange}) {
  return (
    <div className="burger-button">
      <input className="burger-button__checkbox" type="checkbox" onChange={(event) => onChange(event.target.checked)}/>
      <div>
        <span />
        <span />
      </div>
    </div>
  )
}

export default BurgerButton;
