/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import * as React from "react"
import "./header.scss"
import classnames from "classnames"
import BurgerButton from "./BurgerButton/BurgerButton"
import { ModalContext } from "../layout"
import Container from "../Container/Container"

const menu = [
  { title: "portfolio" },
  { title: "services" },
  { title: "testimonials" },
  // { title: "blog" },
  { title: "contacts" },
]

export default function Header() {
  const [isWelcomeBlockVisible, setIsWelcomeBlockVisible] = React.useState(true)

  const [active, setActive] = React.useState(false)

  const { isModalOpened, setIsModalOpened } = React.useContext(ModalContext)

  function handleScroll() {
    const isScrolled = document.documentElement.scrollTop > 0

    setIsWelcomeBlockVisible(!isScrolled)
  }

  React.useEffect(() => {
    document.addEventListener("scroll", handleScroll)

    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const onTitleClick = id => {
    document
      .getElementById(id)
      .scrollIntoView({ behavior: "smooth", block: "start" })
  }

  const onLogoClick = () => {
    if (isModalOpened) {
      setIsModalOpened(false)
      return
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }

  return (
    <div className="header__wrapper">
      <header
        className={classnames("header", {
          "header--background-black": !isWelcomeBlockVisible,
        })}
        role="button"
      >
        <div className="header-main">
          <div className="header-logo" onClick={onLogoClick}>
            <img className="mountain-pass-logo" alt="" />
            <div className="header-logo-text">Mountain Pass</div>
          </div>

          <BurgerButton
            onChange={value => {
              setActive(value)
            }}
          />
        </div>

        <div className={active ? "hidden-menu active" : "hidden-menu"}>
          <Container>
            <div className="menu">
              {menu.map((item, idx) => (
                <div
                  role="button"
                  className="web-h4"
                  onClick={() => onTitleClick(item.title)}
                  key={idx}
                >
                  {` ${item.title} `}
                </div>
              ))}
            </div>
          </Container>
        </div>
      </header>
    </div>
  )
}
