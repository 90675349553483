import * as React from "react"
import "./footer.scss"
import Container from "../Container/Container"

export default function Footer() {
  return (
    <footer>
      <Container>
        <div className="web-h4">
          © {new Date().getFullYear()} Mountain Pass Pty Ltd
          <div className="hidden-mobile">{".\u00A0"}</div>
          <br className="hidden-web" />
          All Rights Reserved
        </div>

        <div className="web-h4">
          ABN 99 602 813 598
          <div className="hidden-mobile">{"\u00A0|\u00A0"}</div>
          <br className="hidden-web" />
          ACN 602 813 598
        </div>
      </Container>
    </footer>
  )
}
