import * as React from "react"
import PropTypes from "prop-types"
import Header from "./Header/header"
import Footer from "./Footer/footer"
import "./layout.css"

export const ModalContext = React.createContext(null)

const Layout = ({ children }) => {
  const [isModalOpened, setIsModalOpened] = React.useState(false)

  React.useEffect(() => {
    if (isModalOpened) {
      document.querySelector("html").classList.add("overflow-hidden")
      return
    }

    if (!isModalOpened) {
      document.querySelector("html").classList.remove("overflow-hidden")
    }
  }, [isModalOpened])

  return (
    <ModalContext.Provider value={{ isModalOpened, setIsModalOpened }}>
      <div className="layout">
        <Header />
        {children}
        <Footer />
      </div>
    </ModalContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
